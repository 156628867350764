











































import Vue from 'vue';
import {mdiEmail, mdiPhone, mdiLink} from '@mdi/js';
import Component from 'vue-class-component';
import PageTitle from '@/components/PageTitle.vue';
import ContactCard from '@/components/ContactCard.vue';

@Component({
  components: {ContactCard, PageTitle},
})
export default class Contact extends Vue {
  emailIcon = mdiEmail;
  phoneIcon = mdiPhone;
  linkIcon = mdiLink;
}
